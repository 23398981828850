var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.rows.length)?_c('b-table',{attrs:{"loading":_vm.loading,"checkable":_vm.checkable,"checked-rows":_vm.checkedRows,"default-sort":['name'],"data":_vm.rows,"detailed":_vm.forScopeSelection,"detail-key":"id","opened-detailed":_vm.forScopeSelection ? _vm.openedDetailed : [],"show-detail-icon":false},on:{"update:checkedRows":function($event){_vm.checkedRows=$event},"update:checked-rows":function($event){_vm.checkedRows=$event}},scopedSlots:_vm._u([(_vm.forScopeSelection)?{key:"detail",fn:function(props){return [(
        _vm.subcustomerIdToSites[props.row.id] === 'loading' ||
        !_vm.subcustomerIdToSites[props.row.id]
      )?_c('b-icon',{staticClass:"fa-spin",attrs:{"icon":"spinner"}}):_c('div',[_c('p',{staticClass:"subtitle has-text-centered"},[_vm._v(" Sites for "),_c('b',[_vm._v(_vm._s(props.row.name))])]),_c('site-table',{attrs:{"rows":_vm.subcustomerIdToSites[props.row.id],"for-scope-selection":_vm.forScopeSelection},on:{"site-selected":function($event){return _vm.$emit('site-selected', $event)}},scopedSlots:_vm._u([{key:"count-columns",fn:function(){return [_c('span')]},proxy:true}],null,true)}),(
          Array.isArray(_vm.subcustomerIdToSites[props.row.id]) &&
          !_vm.subcustomerIdToSites[props.row.id].length
        )?_c('p',{staticClass:"has-text-centered"},[_c('i',[_vm._v(" No sites for this sub-customer/division ")])]):_vm._e()],1)]}}:null],null,true)},[_c('b-table-column',{attrs:{"field":"$displayString","label":"Name","sortable":"","custom-sort":_vm.alphanumericSort},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.$displayString)+" ")]}}],null,false,188256061)}),_vm._t("columns",function(){return [_vm._t("count-columns",function(){return [_c('b-table-column',{attrs:{"sortable":"","numeric":"","label":"Sites"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.sitesFor(props.row).length)+" ")]}}],null,false,2251316067)}),_c('b-table-column',{attrs:{"sortable":"","numeric":"","label":"Areas"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.buildingsFor(props.row).length)+" ")]}}],null,false,1775251742)}),_c('b-table-column',{attrs:{"sortable":"","numeric":"","label":"POUs"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.sourcesFor(props.row).length)+" ")]}}],null,false,1047119349)})]})]}),_vm._t("select-column",function(){return [_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{staticClass:"is-pulled-right",attrs:{"rounded":"","type":"is-primary is-outlined","size":"is-small","icon-right":_vm.forScopeSelection && _vm.openedDetailed.includes(props.row.id)
            ? 'chevron-down'
            : 'chevron-right'},on:{"click":function($event){return _vm.handleSelect(props.row)}}},[_vm._v("Select ")])]}}],null,false,2198459119)})]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }