<template>
  <b-table
    :loading="loading"
    :checkable="checkable"
    :checked-rows.sync="checkedRows"
    v-if="rows.length"
    :default-sort="['name']"
    :data="rows"
    :detailed="forScopeSelection"
    detail-key="id"
    :opened-detailed="forScopeSelection ? openedDetailed : []"
    :show-detail-icon="false"
  >
    <b-table-column field="$displayString" label="Name" sortable v-slot="props" :custom-sort="alphanumericSort"
      >{{ props.row.$displayString }}
    </b-table-column>
    <slot name="columns">
      <slot name="count-columns">
        <b-table-column v-slot="props" sortable numeric label="Sites">
          {{ sitesFor(props.row).length }}
        </b-table-column>
        <b-table-column v-slot="props" sortable numeric label="Areas">
          {{ buildingsFor(props.row).length }}
        </b-table-column>
        <b-table-column v-slot="props" sortable numeric label="POUs">
          {{ sourcesFor(props.row).length }}
        </b-table-column>
      </slot>
    </slot>
    <slot name="select-column">
      <b-table-column v-slot="props">
        <b-button
          @click="handleSelect(props.row)"
          rounded
          type="is-primary is-outlined"
          size="is-small"
          class="is-pulled-right"
          :icon-right="
            forScopeSelection && openedDetailed.includes(props.row.id)
              ? 'chevron-down'
              : 'chevron-right'
          "
          >Select
        </b-button>
      </b-table-column>
    </slot>
    <template #detail="props" v-if="forScopeSelection">
      <b-icon
        icon="spinner"
        class="fa-spin"
        v-if="
          subcustomerIdToSites[props.row.id] === 'loading' ||
          !subcustomerIdToSites[props.row.id]
        "
      />
      <div v-else>
        <p class="subtitle has-text-centered">
          Sites for <b>{{ props.row.name }}</b>
        </p>
        <site-table
          :rows="subcustomerIdToSites[props.row.id]"
          :for-scope-selection="forScopeSelection"
          @site-selected="$emit('site-selected', $event)"
        >
          <template #count-columns><span /></template>
        </site-table>
        <p
          class="has-text-centered"
          v-if="
            Array.isArray(subcustomerIdToSites[props.row.id]) &&
            !subcustomerIdToSites[props.row.id].length
          "
        >
          <i> No sites for this sub-customer/division </i>
        </p>
      </div>
    </template>
  </b-table>
</template>

<script>

import { getChildrenOfId } from "../scripts/filterHelpers";
import SiteTable from "./SiteTable";

export default {
  name: "SubcustTable",
  components: { SiteTable },
  data() {
    return {
      checkedRows: [],
      subcustomerIdToSites: {},
      openedDetailed: [],
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    forScopeSelection: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelect(subcustomer) {
      if (this.forScopeSelection) {
        if (this.openedDetailed.includes(subcustomer.id)) {
          this.openedDetailed = this.openedDetailed.filter(
            (scId) => scId !== subcustomer.id
          );
        } else {
          let self = this;
          self.openedDetailed.push(subcustomer.id);
          self.subcustomerIdToSites[subcustomer.id] = "loading";
          this.$dataClasses.Site.query([
            ["subcustomer", "==", self.toFSRef(subcustomer, "subcustomers")],
          ])
            .then((results) => {
              self.subcustomerIdToSites[subcustomer.id] = results;
              self.subcustomerIdToSites = Object.assign(
                {},
                self.subcustomerIdToSites
              );
            })
            .catch((e) => {
              self.$handleError(e, e);
              self.subcustomerIdToSites[subcustomer.id] = [];
              self.subcustomerIdToSites = Object.assign(
                {},
                this.subcustomerIdToSites
              );
            });
        }
      } else {
        this.$router.push({
          name: "administration:view-subcustomer",
          params: { id: subcustomer.id },
        });
      }
    },
    sitesFor(subcustomer) {
      return getChildrenOfId(this.$store.state.sites, subcustomer.id);
    },
    buildingsFor(subcustomer) {
      let areas = [];
      for (const site of this.sitesFor(subcustomer)) {
        getChildrenOfId(this.$store.state.buildings, site.id).forEach((child) =>
          areas.push(child)
        );
      }
      return areas;
    },
    areasFor(subcustomer) {
      let areas = [];
      for (const site of this.sitesFor(subcustomer)) {
        getChildrenOfId(this.$store.state.areas, site.id).forEach((child) =>
          areas.push(child)
        );
      }
      return areas;
    },
    sourcesFor(subcustomer) {
      let sources = [];
      for (const area of this.areasFor(subcustomer)) {
        getChildrenOfId(this.$store.state.sources, area.id).forEach((child) =>
          sources.push(child)
        );
      }
      return sources;
    },
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
  },
};
</script>

<style scoped></style>
